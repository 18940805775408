import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axiosInstance from '@/services/axios'
import './assets/css/tailwind.css'
import './assets/css/main.css'
import Vuelidate from 'vuelidate'
import DatePicker from 'vue2-datepicker'
import VueHtmlToPaper from 'vue-html-to-paper'
import VueCurrencyInput from 'vue-currency-input'
import 'vue2-datepicker/index.css'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { mapGetters } from 'vuex'

Vue.use(Vuelidate)
Vue.use(DatePicker)
Vue.use(VueCurrencyInput)
Vue.use(Toast)
Vue.component('v-select', vSelect)

const options = {
  name: '_blank',
  specs: [
    'fullscreen=no',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css'
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

// Global components
Vue.component('CustomModal', () => /* webpackChunkName: "modal" */import('@/components/CustomModal.vue'))
Vue.component('CustomButton', () => /* webpackChunkName: "button" */import('@/components/CustomButton.vue'))
Vue.component('CustomInput', () => /* webpackChunkName: "input" */import('@/components/CustomInput.vue'))
Vue.component('NotificationToast', () => /* webpackChunkName: "toast" */import('@/components/notificationToast/NotificationToast.vue'))
Vue.component('ValidationMessage', () => /* webpackChunkName: "validation" */import('@/components/FormValidationMessage.vue'))
Vue.component('LoadingSpinner', () => /* webpackChunkName: "loadingspinner" */import('@/components/UI/PageLoad.vue'))

// Global filters
Vue.filter('moneyFormat', function numberWithCommas(value) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
})

Vue.config.productionTip = false
Vue.prototype.axios = axiosInstance

new Vue({
  router,
  store,
  computed: {
    ...mapGetters(['mode']),
  },
  created() {
    this.toggleTheme()
  },
  watch: {
    mode() {
      this.toggleTheme()
    }
  },
  methods: {
    toggleTheme() {
      if (this.mode === 'dark') {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    }
  },
  render: (h) => h(App),
}).$mount('#app')
